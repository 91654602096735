/* Default Css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
    /* background: linear-gradient(90.21deg, #19222a -5.91%, #3b494a 111.58%); */
  background: linear-gradient(90.21deg, #1a232c -5.91%, #1a232c 111.58%);
  /* background: linear-gradient(90.21deg, #0f1b2e -5.91%, #141f33 111.58%); */
  /* background: linear-gradient(90.21deg, #232d37 -5.91%, #3b494a 111.58%); */
  /* background-color: #121212 !important; */
  color: #fff !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

/* Navbar Css */
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 10px 0;
  background-color: #161e28;
  box-shadow: 0 0 10px 4px rgba(0,0,0,0.6);
}

nav.navbar a.navbar-brand {
    width: 9%;
    color: #fff !important;
    font-size: 20px;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 15px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
    font-weight: 500;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
} 


/* Banner */




.banner {
  text-align: center;
  margin-top: 0;
  padding: 120px 0 90px 0;
  background-image: url('./assets/img/view.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat; 
}

.about {
 border-radius: 10px;
 text-align: left;
 
}

.banner h1{
  font-size: 35px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1;

  display: block;
}



.about .content {
  color: #B8B8B8;
  /* font-size: 18px; */
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 100%;
}


.skill-item {
  border: 1px solid !important;
}

.skill-item > img {
  height: 24px;
  width: 24px;
}

/* header */
.about h2, .projects h2, .blogs h2, .education h2, .contact h2, .skill h2, .contact h2, .project h2 {
  font-size: 30px;
  font-weight: 550;
  text-align: center;
}



.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  color: #fff;
  padding: 15px 24px;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.submit-btn {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
 
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.projects .card, .blogs .card{
  height: 100%;
  /* width: 100px; */
  transition: height 3s ease-in-out, transform 0.8s ease-in-out; 
}

.projects .card:hover, .blogs .card:hover {
  border:2px solid #19222a;
  transform: translateY(-5px);
}

.project .card {
  height: 100%;
  transition: height 3s ease-in-out, transform 0.8s ease-in-out; 
}

.project .card:hover {
  transform: translateY(-5px);
}

.card-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.product-card-image {
  width: 100%;
  height: 150px; 
}

.contain {
  object-fit: contain;
}


/* TimeLine */

.timeline {
  /* color: #93939a; */
  color: #b6b8bb;
}
.timeline {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
}

.timeline .timeline-item {
  position: relative;
}

.timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .timeline-item:after {
  background-color: hsl(0, 0%, 90%);
  left: -38px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
}

.photo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Set the height of the container */
}

.rounded-circle {
  border-radius: 50%; /* This creates a circular shape */
  width: 300px; /* Set the width of the image */
  height: 300px; /* Set the height of the image */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}

.card-contact {
  background: rgba(255, 255, 255, 0.1) !important;
  color: #fff !important;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
}

.contact-info {
  background: rgba(255, 255, 255, 0.1) !important;
  color: #fff !important;
  font-weight: 400;
  font-size: 18px;
  /* margin-top: 25%; */
  /* top:23%; */
  letter-spacing: 0.8px;
  position: relative;
  /* height: 200px;
  width: 200px; */

}


.contact-info img {
  position: absolute;
  transform: translate(-50%, -50%);
  height: 220px;
  width: 220px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid #fff;
}

.contact-info a:hover, .about a:hover {
  transform: scale(1.1);
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(161, 143, 143, 0.5); /* Adjust the color and opacity here */
  z-index: 1; /* Ensure the overlay is above the image */
}

.image {
  display: block;
  width: 100%;
  height: auto;
  z-index: 2; /* Ensure the image is on top of the overlay */
}